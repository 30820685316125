import { Grid, Typography } from "@mui/material";
import React from "react";

export default function DashboardCard({ title, value, pic }) {
  return (
    <Grid
      item
      xs={12}
      md={5}
      lg={3}
      sx={{
        p: 4,
        borderRadius: "8px",
        background: "#F3F8FF",
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            color: "#071C50",
            fontSize: 32,
            fontWeight: 600,
          }}
        >
          {value}
        </Typography>
        <Typography
          sx={{
            color: "#000",
            fontSize: 20,
            opacity: 0.5,
          }}
        >
          {title}
        </Typography>
      </Grid>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={pic} alt="Pic" />
      </div>
    </Grid>
  );
}
