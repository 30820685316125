import React from "react";
import RecordAttendance from "../components/RecordAttendance";
import ViewAttendance from "../components/ViewAttendance";
import { useState } from "react";

export default function AttendancePage() {
  const [view, setView] = useState(true);
  return (
    <div className="container">
      {view && <ViewAttendance setView={setView} />}
      {!view && <RecordAttendance setView={setView} />}
    </div>
  );
}
