import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cityList from "../assets/mockData/cityList.json";
import { useAuthContext } from "../hooks/useAuthContext";

export default function EditEmployee() {
  const { state } = useLocation();
  const oldEmployee = state.employee;
  console.log("Employee", oldEmployee);
  const [isloading, setisloading] = useState(false);
  const [name, setName] = useState(oldEmployee.name);
  const [fathername, setFathername] = useState(oldEmployee.father_name);
  const [cnic, setCnic] = useState(oldEmployee.cnic);
  const [location, setLocation] = useState(oldEmployee.location);
  const [province, setProvice] = useState(oldEmployee.province);
  const [city, setCity] = useState(oldEmployee.city);
  const [empStatus, setEmpStatus] = useState(oldEmployee.empStatus);
  const [designation, setDesignation] = useState(oldEmployee.designation);
  const [gender, setGender] = useState(oldEmployee.gender);
  const [empID, setEmpId] = useState(oldEmployee.empID);
  const [phone, setPhone] = useState(oldEmployee.phone);
  const [joindate, setJoindate] = useState(oldEmployee.join_date);
  const [resigndate, setResignDate] = useState(oldEmployee.resign_date);
  const [salary, setSalary] = useState(oldEmployee.salary);
  const [salaryAccount, setSalaryAccount] = useState(oldEmployee.salaryAccount);
  const [bankName, setBankName] = useState(oldEmployee.bankName);
  const [bankAcc, setBankAcc] = useState(oldEmployee.bankAcc);
  const [err, setErr] = useState(null);
  const [filteredCities, setFilteredCities] = useState([]);

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const bankList = [
    "National Bank of Pakistan",
    "Habib Bank Limited",
    "Allied Bank Limited",
    "Bank Alfalah",
    "United Bank Limited",
    "Faysal Bank Limited",
    "Easypaisa",
    "Jazz Cash",
    "Askari Bank",
    "MCB Bank Limited",
    "Bank AL Habib Limited",
    "Meezan Bank Limited",
    "Bank of Punjab",
    "JS Bank Limited",
    "Standard Chartered Bank (Pakistan) Limited",
    "Summit Bank",
    "Soneri Bank",
    "Habib Metropolitan Bank Limited",
    "BankIslami Pakistan Limited",
    "Silkbank Limited",
    "Standard Chartered Bank",
    "Dubai Islamic Bank",
    "State Bank of Pakistan",
    "Sindh Bank",
    "MCB Bank",
    "Citibank",
  ];

  function getCitiesByProvince(province) {
    var matchingCities = cityList.filter((city) => city.province === province);
    return matchingCities.map((city) => city.name);
  }

  useEffect(() => {
    setFilteredCities(getCitiesByProvince(province));
  }, [province]);

  const provinceNames = [
    "Capital",
    "Punjab",
    "Sindh",
    "Khyber Pakhtunkhwa",
    "Balochistan",
  ];

  const navigate = useNavigate();

  const postform = async () => {
    setisloading(true);
    const response = await axios.put(
      `${baseUrl}/employees/${oldEmployee._id}`,
      {
        empID: empID,
        name: name,
        father_name: fathername,
        gender: gender,
        designation: designation,
        cnic: cnic,
        location: location,
        empStatus: empStatus,
        province: province,
        city: city,
        phone: phone,
        ...(empStatus === "Resigned"
          ? { resign_date: resigndate }
          : { join_date: joindate }),
        salary: salary,
        salaryAccount: salaryAccount,
        bankName: bankName,
        bankAcc: bankAcc,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    if (response.data.error) {
      setErr(response.data.error);
    } else {
      navigate("/");
    }
    setisloading(false);
  };

  return (
    <>
      {isloading && <div className="spinner mx-auto m-5"></div>}
      {err && (
        <div className="alert alert-danger w-50 mx-auto text-center">
          <h4>{err}</h4>
        </div>
      )}
      {!isloading && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            postform();
          }}
          className="mx-auto text-center"
        >
          <h3>Personal Details</h3>
          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Full Name"
            value={name}
            type="text"
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <br />
          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Father Name"
            value={fathername}
            type="text"
            onChange={(e) => {
              setFathername(e.target.value);
            }}
          />
          <br />
          <select
            required
            value={gender}
            onChange={(e) => {
              setGender(e.target.value);
            }}
            className="form-select w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1 mx-auto"
          >
            <option value="" disabled selected hidden>
              Gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          <input
            className="w-50 border-bottom my-2 mb-4 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="CNIC ( xxxxx-xxxxxxx-x )"
            maxLength="15"
            minLength="15"
            value={cnic}
            type="text"
            pattern="\d{5}-\d{7}-\d"
            onChange={(e) => {
              setCnic(e.target.value);
            }}
          />
          <br />
          <input
            className="w-50 border-bottom mb-2 mt-0 border-0 border-secondary px-3 pt-2 pb-1"
            required
            placeholder="Phone No. ( 03xx-xxxxxxx )"
            type="text"
            maxLength="12"
            value={phone}
            minLength="12"
            pattern="03[0-9]{2}-[0-9]{7}"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          <br />
          <h3 className="mt-5">Professional Details</h3>
          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Employee ID"
            value={empID}
            type="number"
            onChange={(e) => {
              setEmpId(e.target.value);
            }}
          />
          <br />
          <div className="d-flex justify-content-center w-50 mx-auto gap-4">
            <select
              required
              value={province}
              onChange={(e) => {
                setProvice(e.target.value);
              }}
              class="form-select border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1 mx-auto"
            >
              <option value="" disabled hidden selected>
                Provice
              </option>
              {provinceNames &&
                provinceNames.map((prov) => (
                  <option key={prov} value={prov}>
                    {prov}
                  </option>
                ))}
            </select>
            <select
              required
              disabled={!province}
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              class="form-select border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1 mx-auto"
            >
              <option value="" disabled hidden selected>
                City
              </option>
              {filteredCities &&
                filteredCities.map((city) => (
                  <option key={city} value={city}>
                    {city}
                  </option>
                ))}
            </select>
          </div>
          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Location"
            value={location}
            type="text"
            onChange={(e) => {
              setLocation(e.target.value);
            }}
          />
          <br />
          <select
            required
            value={salaryAccount}
            onChange={(e) => {
              setSalaryAccount(e.target.value);
            }}
            class="form-select w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1 mx-auto"
          >
            <option value="" disabled hidden selected>
              Salary Account
            </option>
            <option value="Cash">Cash</option>
            <option value="Bank">Bank</option>
          </select>
          {salaryAccount === "Bank" && (
            <div className="d-flex justify-content-center w-50 mx-auto gap-4">
              <select
                required
                value={bankName}
                onChange={(e) => {
                  setBankName(e.target.value);
                }}
                class="form-select w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1 mx-auto"
              >
                <option value="" disabled hidden selected>
                  Bank Name
                </option>
                {bankList &&
                  bankList.map((bank) => (
                    <option key={bank} value={bank}>
                      {bank}
                    </option>
                  ))}
              </select>
              <input
                className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
                required
                placeholder="Account Number"
                type="text"
                value={bankAcc}
                onChange={(e) => {
                  setBankAcc(e.target.value);
                }}
              />
            </div>
          )}
          <br />
          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Designation"
            type="text"
            value={designation}
            onChange={(e) => {
              setDesignation(e.target.value);
            }}
          />
          <br />
          <select
            required
            value={empStatus}
            onChange={(e) => {
              setEmpStatus(e.target.value);
            }}
            className="form-select w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1 mx-auto"
          >
            <option value="" disabled selected hidden>
              Status
            </option>
            <option value="Currently Working">Currently Working</option>
            <option value="Resigned">Resigned</option>
          </select>
          <input
            className="w-50 border-bottom mb-2 mt-4 mb-4 border-0 border-secondary px-3 pb-1"
            required
            placeholder="Joining Date"
            type="date"
            value={empStatus === "Resigned" ? resigndate : joindate}
            onChange={(e) => {
              empStatus === "Resigned"
                ? setResignDate(e.target.value)
                : setJoindate(e.target.value);
            }}
          />
          <br />
          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pb-1"
            required
            placeholder="Salary"
            type="number"
            value={salary}
            onChange={(e) => {
              setSalary(e.target.value);
            }}
          />
          <br /> <br />
          <br />
          <input
            className="btn mb-5 btn-primary w-50 rounded-5 fs-4 pb-1"
            type="submit"
            value="Save Employee"
          />
        </form>
      )}
    </>
  );
}
