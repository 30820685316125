import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import axios from "axios";

export default function EmployeeAttendance() {
  const { id } = useParams();
  const [attendance, setAttendance] = useState(null);
  const [isloading, setisloading] = useState(true);
  const [orderBy, setOrderBy] = useState("date");
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchEmployeeAttendance();
  }, []);

  const fetchEmployeeAttendance = async () => {
    setisloading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/attendance/byemployee/${id}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setAttendance(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching attendance:", error);
    } finally {
      setisloading(false);
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedAttendance = (attendance) => {
    if (!orderBy) return attendance;

    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    return [...attendance]
      .sort((a, b) => {
        const aValue = a[orderBy];
        const bValue = b[orderBy];
        return order === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      })
      .slice(startIndex, endIndex);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container">
      {isloading && <div className="spinner mx-auto m-5"></div>}
      {!isloading && attendance && (
        <>
          <h2>Attendance History</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "date"}
                      direction={orderBy === "date" ? order : "asc"}
                      onClick={() => handleSort("date")}
                    >
                      <b>Date</b>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Overtime</b>
                  </TableCell>
                  <TableCell>
                    <b>Double OT</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAttendance(attendance).map((data) => (
                  <TableRow key={data.date}>
                    <TableCell>{data.date.slice(0, 10)}</TableCell>
                    <TableCell>{data.status}</TableCell>
                    <TableCell>{data.overTime || 0}</TableCell>
                    <TableCell>{data.doubleOT ? "Yes" : "No"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={false}
              component="div"
              count={attendance.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
    </div>
  );
}
