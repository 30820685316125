import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Modal,
  Box,
  Tooltip,
} from "@mui/material";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import PrintIcon from "@mui/icons-material/Print";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { Delete as DeleteIcon } from "@mui/icons-material";

export default function InvoiceCard({
  invoice,
  sendDeleteRequest,
  sendApproveRequest,
  user,
}) {
  const [detailModal, setDetailModal] = useState(false);

  const printVoucher = async (data) => {
    // Create a new document with paragraphs
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            // Add a heading
            new Paragraph({
              text: "Invoice Details",
              heading: "Heading1",
              bold: true,
              size: 16,
            }),

            // Add heading-value pairs using tabs
            new Paragraph({
              children: [
                new TextRun({ text: "Invoice_Number:", bold: true }),
                new TextRun("\t\t\t" + data.invoiceNumber),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "Clinet:", bold: true }),
                new TextRun("\t\t\t" + data.client),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "Created_at:", bold: true }),
                new TextRun("\t\t\t" + data.createDate.toString().slice(0, 10)),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "Amount_Before_Tax:", bold: true }),
                new TextRun("\t\t\t" + data.amountBFT),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "Tax:", bold: true }),
                new TextRun("\t\t\t" + `${data.taxAmount}%`),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "Amount_After_Tax:", bold: true }),
                new TextRun("\t\t\t" + data.amountAFT),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "Payment_Recieved:", bold: true }),
                new TextRun("\t\t\t" + (data.isRecieved ? "Yes" : "No")),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "Recieved_At:", bold: true }),
                new TextRun(
                  "\t\t\t" +
                    (data.recieveDate
                      ? data.recieveDate.toString().slice(0, 10)
                      : "N/A")
                ),
              ],
            }),
          ],
        },
      ],
    });

    // Set metadata properties
    doc.title = `Invoice-${data.invoiceNumber}`;
    doc.description = `${data.client}`;
    doc.creator = "SFM";

    try {
      // Use Packer.toBlob to get a Blob
      const blob = await Packer.toBlob(doc);

      // Save the Blob to a file using file-saver
      saveAs(blob, `Invoice-${data.invoiceNumber}.docx`);
    } catch (error) {
      console.error("Error creating the document:", error);
    }
  };

  return (
    <Card
      sx={{
        p: 2,
        pb: 0,
        borderRadius: "8px",
        background: invoice.isRecieved ? "#F3F8FF" : "#fcdbd9",
        height: "100%",
      }}
      elevation={0}
    >
      <CardContent sx={{ p: 0 }}>
        <span
          style={{
            textDecoration: "none",
            margin: 0,
            padding: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            setDetailModal(true);
          }}
        >
          <Typography
            sx={{
              color: "#071C50",
              fontSize: 24,
              fontWeight: 600,
            }}
            variant="h5"
          >
            PKR {invoice.amountAFT}
          </Typography>
        </span>
        <Typography component="small" sx={{ fontSize: "1rem" }}>
          {invoice.invoiceNumber}
        </Typography>

        <Typography mt={2} fontWeight={500} color="secondary">
          {invoice.createDate.slice(0, 10)}
        </Typography>
        <Typography mt={2} variant="body2">
          {invoice.client}
        </Typography>
      </CardContent>
      <Modal
        open={detailModal}
        onClose={() => {
          setDetailModal(false);
        }}
        closeAfterTransition
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "20px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography textAlign={"center"} variant="h6">
            Invoice No. {invoice.invoiceNumber}
          </Typography>

          <Grid display={"grid"} mt={2} gap={3}>
            <Typography className="card-text">
              <strong> Client:</strong> {invoice.client}
            </Typography>
            <Typography className="card-text">
              <strong> Amount before tax:</strong> {invoice.amountBFT}
            </Typography>
            <Typography className="card-text">
              <strong> Tax amount:</strong> {invoice.taxAmount}%
            </Typography>
            <Typography className="card-text">
              <strong> Amount after tax:</strong> {invoice.amountAFT}
            </Typography>
            <Typography className="card-text">
              <strong className="text-black"> Created at: </strong>
              {invoice.createDate}
            </Typography>

            {invoice.isRecieved ? (
              <>
                <Typography className="card-text">
                  <strong> Approval date:</strong> {invoice.recieveDate}
                </Typography>
                <span className="badge bg-success">Approved</span>
              </>
            ) : (
              <span className="badge bg-warning text-dark">
                Pending Approval
              </span>
            )}
          </Grid>

          <Grid
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
            mt={2}
          >
            {invoice.isRecieved && (
              <>
                {printVoucher && (
                  <Tooltip title="Print">
                    <IconButton
                      onClick={() => {
                        printVoucher(invoice);
                      }}
                    >
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Unapprove">
                  <IconButton
                    onClick={() => {
                      sendDeleteRequest(invoice._id, true);
                    }}
                  >
                    <ThumbDownIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {!invoice.isRecieved && user.isAdmin && (
              <Tooltip title="Approve">
                <IconButton
                  onClick={(e) => {
                    sendApproveRequest(invoice._id);
                  }}
                  size="small"
                >
                  <PriceCheckIcon color="success" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  sendDeleteRequest(invoice._id, false);
                }}
                size="small"
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Box>
      </Modal>
    </Card>
  );
}
