import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import Drawer from "@mui/material/Drawer";
import PeopleIcon from "@mui/icons-material/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import clientLogo from "../assets/client_logo.png";
import logo from "../assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PaymentsIcon from "@mui/icons-material/Payments";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { useAuthContext } from "../hooks/useAuthContext";
import { Button, Stack } from "@mui/material";
import { ArrowBack, ManageAccounts } from "@mui/icons-material";

const drawerWidth = 110;

function ResponsiveDrawer(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const { logout } = useLogout();
  const { user } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{ overflowX: "hidden" }}>
      <Toolbar />
      <Divider />
      <List
        sx={{
          display: "grid",
          gap: 1,
        }}
      >
        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "#CFD8DC",
            opacity: 1,
          }}
          button
          component={NavLink}
          to="/"
        >
          <DashboardIcon />
          <Typography variant="body1" style={{ fontSize: "14px" }}>
            Dashboard
          </Typography>
        </ListItem>

        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "#CFD8DC",
            opacity: 1,
          }}
          button
          component={NavLink}
          to="/employees"
        >
          <PeopleIcon />
          <Typography variant="body1" style={{ fontSize: "14px" }}>
            Employees
          </Typography>
        </ListItem>

        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "#CFD8DC",
            opacity: 1,
          }}
          button
          component={NavLink}
          to="/attendance"
        >
          <AppRegistrationIcon />
          <Typography variant="body1" style={{ fontSize: "14px" }}>
            Attendance
          </Typography>
        </ListItem>

        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "#CFD8DC",
            opacity: 1,
          }}
          button
          component={NavLink}
          to="/vouchers"
        >
          <LocalAtmIcon />
          <Typography variant="body1" style={{ fontSize: "14px" }}>
            Vouchers
          </Typography>
        </ListItem>

        {user?.isAdmin && (
          <>
            <ListItem
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#CFD8DC",
                opacity: 1,
              }}
              button
              component={NavLink}
              to="/customers"
            >
              <Diversity3Icon />
              <Typography variant="body1" style={{ fontSize: "14px" }}>
                Customers
              </Typography>
            </ListItem>

            <ListItem
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#CFD8DC",
                opacity: 1,
              }}
              button
              component={NavLink}
              to="/invoices"
            >
              <ReceiptIcon />
              <Typography variant="body1" style={{ fontSize: "14px" }}>
                Invoices
              </Typography>
            </ListItem>

            <ListItem
              sx={{
                display: "flex",
                flexDirection: "column",
                color: "#CFD8DC",
                opacity: 1,
              }}
              button
              component={NavLink}
              to="/payroll"
            >
              <PaymentsIcon />
              <Typography variant="body1" style={{ fontSize: "14px" }}>
                Payroll
              </Typography>
            </ListItem>
          </>
        )}
      </List>

      <Divider />

      {user && (
        <List>
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#CFD8DC",
              opacity: 1,
            }}
            button
            component={NavLink}
            to="/users"
          >
            <ManageAccounts />
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              Moderators
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "#F7AC25",
            }}
            button
            onClick={handleLogout}
          >
            <ExitToAppIcon />
            <Typography variant="body1" style={{ fontSize: "14px" }}>
              Logout
            </Typography>
          </ListItem>
        </List>
      )}
      <List sx={{ position: "absolute", bottom: 10, width: "100%" }}>
        <ListItem
          sx={{
            display: "flex",
            flexDirection: "column",
            color: "#CFD8DC",
            opacity: 1,
          }}
          button
          component={Link}
          to={-1}
        >
          <ArrowBack />
          <Typography variant="body1" style={{ fontSize: "14px" }}>
            Back
          </Typography>
        </ListItem>
      </List>
    </div>
  );

  return location.pathname !== "/login" ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#fff",
          boxShadow: "none",
          color: "#000",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex align-items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <div className="d-flex gap-2 align-items-center">
              <img
                style={{
                  width: "64px",
                }}
                src={clientLogo}
                alt="logo"
              />
              <Typography
                fontSize={{ xs: 11, md: 16, lg: 18, xl: 20 }}
                fontWeight={"bold"}
                color={"primary"}
              >
                SOLUTION FACILITIES MANAGEMENT (SMC-PVT) LTD.
              </Typography>
            </div>
          </div>
          <div>
            <img
              style={{
                width: "64px",
              }}
              src={logo}
              alt="logo"
            />
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRadius: "0px 8px 8px 0px",
              background: "#474747",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRadius: "0px 8px 8px 0px",
              background: "#474747",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          background: "#E5EDF9",
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  ) : (
    props.children
  );
}

export default ResponsiveDrawer;
