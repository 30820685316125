import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Modal,
  Box,
  Tooltip,
} from "@mui/material";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import PrintIcon from "@mui/icons-material/Print";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useGet } from "../hooks/useGet";

export default function VoucherCard({
  voucher,
  sendDeleteRequest,
  sendApproveRequest,
  user,
  printVoucher,
}) {
  const [detailModal, setDetailModal] = useState(false);

  const { getUserById } = useGet();

  const [addedBy, setAddedBy] = useState("");

  useEffect(() => {
    const fetchName = async () => {
      const userResponse = await getUserById(voucher.addedBy);
      setAddedBy(userResponse);
    };
    if (voucher.addedBy) fetchName();
  }, []);

  if (addedBy) console.log(addedBy);

  return (
    <Card
      sx={{
        p: 2,
        pb: 0,
        borderRadius: "8px",
        background: voucher.isApproved ? "#F3F8FF" : "#fcdbd9",
        height: "100%",
      }}
      elevation={0}
    >
      <CardContent sx={{ p: 0 }}>
        <span
          style={{
            textDecoration: "none",
            margin: 0,
            padding: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            setDetailModal(true);
          }}
        >
          <Typography
            sx={{
              color: "#071C50",
              fontSize: 24,
              fontWeight: 600,
            }}
            variant="h5"
          >
            Voucher No. {voucher.voucherNumber}
          </Typography>
        </span>
        <Typography component="small" sx={{ fontSize: "1rem" }}>
          {voucher.category}, {voucher.location}
        </Typography>

        <Typography mt={2} fontWeight={500} color="secondary">
          {voucher.balance}
        </Typography>
        <Typography mt={2} variant="body2">
          {voucher.description}
        </Typography>
        {addedBy?.name?.length && (
          <Typography mt={2} variant="body2">
            Added By {addedBy.name}
          </Typography>
        )}
      </CardContent>
      <Modal
        open={detailModal}
        onClose={() => {
          setDetailModal(false);
        }}
        closeAfterTransition
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "20px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography textAlign={"center"} variant="h6">
            Voucher No. {voucher.voucherNumber}
          </Typography>

          <Typography textAlign={"center"} variant="h6">
            Added By {addedBy.name}
          </Typography>

          <Grid display={"grid"} mt={2} gap={3}>
            <Typography className="card-text">
              <strong> Description:</strong> {voucher.description}
            </Typography>
            <Typography className="card-text">
              <strong> Category:</strong> {voucher.category}
            </Typography>
            <Typography className="card-text">
              <strong> Location:</strong> {voucher.location}
            </Typography>
            <Typography className="card-text">
              <strong> Account:</strong> {voucher.account}
            </Typography>
            <Typography className="card-text text-danger">
              <strong className="text-black"> Debit Amount: </strong>
              {voucher.debitAmount}
            </Typography>
            <Typography className="card-text text-success">
              <strong className="text-black"> Credit Amount: </strong>
              {voucher.creditAmount}
            </Typography>
            <Typography className={`card-text`}>
              <strong> Balance: </strong> {voucher.balance}
            </Typography>
            <Typography className="card-text">
              <strong> Date:</strong> {voucher.date.slice(0, 10)}
            </Typography>
            {voucher.isApproved ? (
              <span className="badge bg-success">Approved</span>
            ) : (
              <span className="badge bg-warning text-dark">
                Pending Approval
              </span>
            )}
          </Grid>

          <Grid
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
            mt={2}
          >
            {voucher.isApproved && (
              <>
                <Tooltip title="Print">
                  <IconButton
                    onClick={() => {
                      printVoucher(voucher);
                    }}
                  >
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Unapprove">
                  <IconButton
                    onClick={() => {
                      sendDeleteRequest(voucher._id, true);
                    }}
                  >
                    <ThumbDownIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {!voucher.isApproved && user.isAdmin && (
              <Tooltip title="Approve">
                <IconButton
                  onClick={(e) => {
                    sendApproveRequest(voucher._id);
                  }}
                  size="small"
                >
                  <PriceCheckIcon color="success" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  sendDeleteRequest(voucher._id, false);
                }}
                size="small"
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Box>
      </Modal>
    </Card>
  );
}
