import axios from "axios";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useParams } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { Col, Row } from "react-bootstrap";
import { IconButton, Tooltip } from "@mui/material";
import { FileUpload } from "@mui/icons-material";

export default function EmployeeProfile() {
  const { id } = useParams();
  const [employee, setEmployee] = useState(null);
  const [isloading, setisloading] = useState(true);

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const exportToExcel = () => {
    const exportData = [employee];

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    saveAs(blob, `employee-${employee.name}.xlsx`);
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  const fetchEmployee = async () => {
    setisloading(true);
    await axios
      .get(`${baseUrl}/employees/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        setEmployee(response.data);
        console.log(employee);
      })
      .catch((error) => {
        console.error("Error fetching employee:", error);
      });
    setisloading(false);
  };

  return (
    <div className="container">
      {isloading && <div className="spinner mx-auto m-5"></div>}
      {!isloading && employee && (
        <>
          <MDBRow className="justify-content-center align-items-center h-100">
            <MDBCol className="mb-4 mb-lg-0">
              <MDBCard className="mb-3" style={{ borderRadius: ".5rem" }}>
                <MDBRow className="g-0">
                  <MDBCol
                    md="4"
                    className="gradient-custom text-center text-white"
                    style={{
                      borderTopLeftRadius: ".5rem",
                      borderBottomLeftRadius: ".5rem",
                    }}
                  >
                    <MDBCardImage
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"
                      alt="Avatar"
                      className="my-5"
                      style={{ width: "80px" }}
                      fluid
                    />
                    <MDBTypography tag="h5">{employee.name}</MDBTypography>
                    <MDBCardText>{employee.designation}</MDBCardText>
                    <MDBIcon far icon="edit mb-5" />
                  </MDBCol>
                  <MDBCol md="8">
                    <MDBCardBody className="p-4">
                      <div className="d-flex justify-content-between align-items-center">
                        <MDBTypography tag="h6">
                          Personal Information
                        </MDBTypography>
                        <Tooltip title="Export">
                          <IconButton onClick={exportToExcel}>
                            <FileUpload />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <hr className="mt-0 mb-4" />
                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">CNIC</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.cnic}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Father Name</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.father_name}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Gender</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.gender}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>

                      <br />
                      <br />

                      <MDBTypography tag="h6">
                        Professional Details
                      </MDBTypography>
                      <hr className="mt-0 mb-4" />
                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Employee ID</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.empID}
                          </MDBCardText>
                        </MDBCol>

                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Status</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.empStatus}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Salary</MDBTypography>
                          <MDBCardText className="text-muted">
                            PKR {employee.salary}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Joining Date</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.join_date.slice(0, 10)}
                          </MDBCardText>
                        </MDBCol>
                        {employee.empStatus === "Resigned" && (
                          <MDBCol size="6" className="mb-3">
                            <MDBTypography tag="h6">
                              Resignation Date
                            </MDBTypography>
                            <MDBCardText className="text-muted">
                              {employee.resign_date.slice(0, 10)}
                            </MDBCardText>
                          </MDBCol>
                        )}
                      </MDBRow>

                      <br />
                      <br />

                      <MDBTypography tag="h6">
                        Contact Information
                      </MDBTypography>
                      <hr className="mt-0 mb-4" />
                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Province</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.province}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">City</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.city}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Location</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.location}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Phone Number</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.phone}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>

                      <br />
                      <br />

                      <MDBTypography tag="h6">Payment Details</MDBTypography>
                      <hr className="mt-0 mb-4" />
                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Payment Method</MDBTypography>
                          <MDBCardText className="text-muted">
                            {employee.salaryAccount}
                          </MDBCardText>
                        </MDBCol>
                        {employee.salaryAccount === "Bank" && (
                          <>
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Bank Name</MDBTypography>
                              <MDBCardText className="text-muted">
                                {employee.bankName}
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">
                                Account Number
                              </MDBTypography>
                              <MDBCardText className="text-muted">
                                {employee.bankAcc}
                              </MDBCardText>
                            </MDBCol>
                          </>
                        )}
                      </MDBRow>

                      <div className="d-flex justify-content-start">
                        <a href="#!">
                          <MDBIcon fab icon="facebook me-3" size="lg" />
                        </a>
                        <a href="#!">
                          <MDBIcon fab icon="twitter me-3" size="lg" />
                        </a>
                        <a href="#!">
                          <MDBIcon fab icon="instagram me-3" size="lg" />
                        </a>
                      </div>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <h2>Payout history</h2>
            <Row>
              <Col>
                <b>Payout Date</b>
              </Col>

              <Col>
                <b> Payout Amount</b>
              </Col>
            </Row>
            {employee.payOuts?.map((payout) => (
              <Row>
                <Col>{payout.payDay}</Col>

                <Col>{payout.payAmount}</Col>
              </Row>
            ))}
          </MDBRow>
        </>
      )}
    </div>
  );
}
