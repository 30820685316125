import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useAuthContext } from "../hooks/useAuthContext";
import axios from "axios";
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AddCustomer() {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [personInContact, setPersonInContact] = useState("");
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const postForm = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${baseUrl}/customers`,
        {
          name: name,
          address: address,
          personInContact: personInContact,
          phone: phone,
          e_mail: email,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.data.error) {
        setError(response.data.error);
      } else {
        // Clear form fields
        setName("");
        setAddress("");
        setPersonInContact("");
        setPhone("");
        setEmail("");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error adding customer:", error);
      setError("Failed to add customer");
    }

    setIsLoading(false);
  };

  return (
    <div className="container">
      {isLoading && <CircularProgress className="spinner mx-auto m-5" />}
      {error && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={<h4>{error}</h4>}
        />
      )}
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={3.5}
      >
        <Grid item>
          <Typography
            fontSize={{ xs: 26, sm: 28, md: 32, lg: 34 }}
            variant="h4"
          >
            Add Customer
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/customers");
            }}
          >
            All Customers
          </Button>
        </Grid>
      </Grid>
      {!isLoading && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            postForm();
          }}
          className="mx-auto text-center"
        >
          <TextField
            size="medium"
            color="secondary"
            className="w-50 my-2"
            required
            label="Full Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <TextField
            size="medium"
            color="secondary"
            className="w-50 my-2"
            label="Address"
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <br />
          <TextField
            size="medium"
            color="secondary"
            className="w-50 my-2"
            label="Person in Contact"
            variant="outlined"
            value={personInContact}
            onChange={(e) => setPersonInContact(e.target.value)}
          />
          <br />
          <InputMask
            mask="9999-9999999"
            value={phone}
            onChange={(e) => {
              const formattedPhone = e.target.value.replace(/-/g, "");
              setPhone(formattedPhone);
            }}
          >
            {(inputProps) => (
              <TextField
                className="w-50 my-2"
                color="secondary"
                size="medium"
                label="Phone"
                required
                type="text"
                maxLength="12"
                minLength="12"
                {...inputProps}
              />
            )}
          </InputMask>
          <br />
          <TextField
            size="medium"
            color="secondary"
            className="w-50 my-2"
            required
            label="Email"
            variant="outlined"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <br />
          <Button
            className="mb-5"
            variant="contained"
            color="secondary"
            type="submit"
          >
            Add Customer
          </Button>
        </form>
      )}
    </div>
  );
}
