import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { Button, CircularProgress } from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Pagination from "@mui/material/Pagination";
import { useAuthContext } from "../hooks/useAuthContext";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default function Payroll() {
  const [payroll, setPayroll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dispatchModal, setDispatchModal] = useState(false);
  const [num, setNum] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [adjAmountModal, setAdjAmountModal] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState("empID");
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);

  const [ajdData, setAdjData] = useState({
    advance: 0,
    fine: 0,
    eobi: 0,
    allowance: 0,
    arrears: 0,
    bonus: 0,
  });
  const [dispatchData, setDispatchData] = useState({ date: "", amount: "" });

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleSearchCriteriaChange = (event) => {
    setSearchCriteria(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const searchEmployees = () => {
    let filteredPayroll = payroll;

    return filteredPayroll;
  };

  const filteredPayroll = searchEmployees();

  const fetchPayrolls = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/payroll?page=${page}&searchCriteria=${searchCriteria}&searchQuery=${searchQuery}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setIsLoading(false);
      setPayroll(response.data.payrollData);
      setPageCount(response.data.pageCount);
      console.log(payroll);
    } catch (error) {
      alert("An unexpected error occured.");
      console.error("Error fetching invoices:", error);
    }
  };

  const sendDispatchRequest = async () => {
    setIsLoading(true);
    const res = await axios
      .put(
        `${baseUrl}/employees/paysalary/${num}`,
        {
          payDay: dispatchData.date,
          payAmount: dispatchData.amount,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .catch((e) => {
        alert("An unexpected error occured.");
        console.log("Error: ", e);
      });
    setIsLoading(false);
    if (res.status !== 200) {
      alert("Failed to dispatch salary.");
    } else {
      fetchPayrolls();
      setDispatchData({});
      setDispatchModal(false);
    }
  };

  const sendAdjAmountRequest = async () => {
    setIsLoading(true);
    const res = await axios
      .put(
        `${baseUrl}/payroll/alter/${num}`,
        {
          advance: ajdData.advance,
          fine: ajdData.fine,
          eobi: ajdData.eobi,
          allowance: ajdData.allowance,
          arrears: ajdData.arrears,
          bonus: ajdData.bonus,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .catch((e) => {
        alert("An unexpected error occured.");
        console.log("Error: ", e);
      });
    setIsLoading(false);
    if (res.status !== 200) {
      alert("Failed to change values.");
    } else {
      fetchPayrolls();
      setAdjData({
        advance: 0,
        fine: 0,
        eobi: 0,
        allowance: 0,
        arrears: 0,
        bonus: 0,
      });
      setAdjAmountModal(false);
    }
  };

  const triggerSalaryControl = async (num) => {
    setIsLoading(true);
    const res = await axios
      .put(
        `${baseUrl}/employees/salarycontrol/${num}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .catch((e) => {
        alert("An unexpected error occured.");
        console.log("Error: ", e);
      });
    setIsLoading(false);
    if (res.status !== 200) {
      alert("Failed to trigger control.");
    } else {
      fetchPayrolls();
    }
  };

  useEffect(() => {
    fetchPayrolls();
  }, [page]);

  const exportToExcel = async () => {
    if (!startDate || !endDate) {
      alert("Please enter start and end date for export");
      return;
    }
    setExportLoading(true);
    let exportData = [];
    const response = await axios
      .get(
        `${baseUrl}/payroll?limit=all&page=1&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .catch((e) => {
        alert("An unexpected error occured.");
        console.log("Error: ", e);
        setExportLoading(false);
      });

    if (!response) return;

    let expPayroll = response?.data?.payrollData;

    expPayroll.forEach((data) => {
      exportData.push({
        Employee_ID: data.employee.empID,
        Name: data.employee.name,
        Father_Name: data.employee.father_name,
        Location: data.employee.location,
        Employee_Status: data.employee.empStatus,
        CNIC: data.employee.cnic,
        Designation: data.employee.designation,
        Base_Salary: data.employee.salary,
        Salary_Account: data.employee.salaryAccount,
        bank_Name: data.employee.bankName,
        Account_Number: data.employee.bankAcc,
        Days_Worked: data.daysWorked,
        Hours_Worked: data.hoursWorked,
        Overtime: data.overTime,
        Allowance: data.allowance,
        Arrears: data.arrears,
        Bonus: data.bonus,
        EOBI: data.eobi,
        Fine: data.fine,
        Advance: data.advance,
        Salary_With_OT: data.totalSalary,
        Accumulated_Salary: data.accumulatedSalary,
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    const date = new Date();

    saveAs(blob, `payroll-${date}.xlsx`);
    setExportLoading(false);
  };

  return (
    <div className="container">
      {!isLoading ? (
        <Row>
          <form onSubmit={fetchPayrolls}>
            <div className="row align-items-center border-bottom mb-5">
              <div className="col col-md-2 mb-3">
                <label htmlFor="searchCriteria" className="form-label">
                  Search By:
                </label>
                <select
                  id="searchCriteria"
                  name="searchCriteria"
                  value={searchCriteria}
                  onChange={handleSearchCriteriaChange}
                  className="form-select"
                >
                  <option value="empID">ID</option>
                  <option value="name">Name</option>
                  <option value="cnic">CNIC</option>
                  <option value="salaryControl">Status</option>
                </select>
              </div>
              <div className="col col-md-2 mb-3">
                <label htmlFor="searchQuery" className="form-label">
                  Search Query:
                </label>
                <input
                  type="text"
                  id="searchQuery"
                  name="searchQuery"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                  className="form-control"
                />
              </div>
              <div className="col col-md-2 mb-3">
                <label htmlFor="searchQuery" className="form-label">
                  Start Date:
                </label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                  className="form-control"
                />
              </div>
              <div className="col col-md-2 mb-3">
                <label htmlFor="searchQuery" className="form-label">
                  End Date:
                </label>
                <input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                  className="form-control"
                />
              </div>
              <div className="col col-md-1 mt-3 d-flex justify-content-end align-items-center">
                <Button variant="contained" color="secondary" type="submit">
                  <SearchIcon />
                </Button>
              </div>
              <div className="col mt-3 d-flex justify-content-end">
                <Button variant="contained" onClick={exportToExcel}>
                  {exportLoading ? <CircularProgress size={24} /> : "Export"}
                </Button>
              </div>
            </div>
          </form>

          {filteredPayroll?.map((stat) => (
            <Col key={stat._id} xs={12} lg={6} className="mb-3">
              <Accordion>
                <Accordion.Item eventKey={stat._id}>
                  <Accordion.Header>
                    {`${stat.employee.empID} - ${stat.employee.name} - ${stat.employee.city}`}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col className="d-grid">
                        <b> Days Worked:</b>
                        <b> Overtime:</b>
                        <b> Hours Worked:</b>
                        <b> Base Salary:</b>
                        <b> Hourly Salary:</b>
                        <b> Salary with OT:</b>
                        <b> Allowance:</b>
                        <b> Bonus:</b>
                        <b> Arrears:</b>
                        <b> Fine:</b>
                        <b> EOBI:</b>
                        <b> Advance amount:</b>
                        <b className="text-success"> Accumulated Salary:</b>
                      </Col>
                      <Col className="d-grid">
                        <p> {stat.daysWorked}</p>
                        <p> {stat.overTime} hours</p>
                        <p> {stat.hoursWorked} hours</p>
                        <p> PKR {stat.baseSalary}</p>
                        <p> PKR {stat.employee.hourlyRate}</p>
                        <p className="text-primary"> PKR {stat.totalSalary}</p>
                        <p className="text-primary"> PKR {stat.allowance}</p>
                        <p className="text-primary"> PKR {stat.bonus}</p>
                        <p className="text-primary"> PKR {stat.arrears}</p>
                        <p className="text-danger"> PKR {stat.fine}</p>
                        <p className="text-danger"> PKR {stat.eobi}</p>
                        <p className="text-danger"> PKR {stat.advance}</p>
                        <p className="text-success fw-bold">
                          PKR {stat.accumulatedSalary}
                        </p>
                      </Col>
                    </Row>
                    <div className="d-flex justify-content-between align-items-center gap-2">
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setNum(stat.employeeId);
                          setAdjAmountModal(true);
                          setAdjData({
                            empID: stat.employee._id,
                            advance: stat.advance,
                            fine: stat.fine,
                            eobi: stat.eobi,
                            allowance: stat.allowance,
                            arrears: stat.arrears,
                            bonus: stat.bonus,
                          });
                        }}
                      >
                        Adjust Amount
                      </Button>
                      <Button size="small" variant="contained">
                        <Link
                          className="text-decoration-none text-light"
                          to={`/employees/${stat.employee._id}`}
                        >
                          View Profile
                        </Link>
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          triggerSalaryControl(stat.employee._id);
                        }}
                      >
                        Mark as{" "}
                        {stat.employee.salaryControl ? "Blocked" : "Active"}
                      </Button>
                      {stat.employee.salaryControl && (
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            setNum(stat.employeeId);
                            setDispatchModal(true);
                            setDispatchData({
                              date: "",
                              amount: stat.accumulatedSalary,
                            });
                          }}
                        >
                          Dispatch Salary
                        </Button>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Modal
                className="mt-5"
                show={dispatchModal}
                onHide={() => {
                  setDispatchModal(false);
                  setDispatchData({ date: "", amount: "" });
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Dispatch employee salary</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    type="date"
                    onChange={(e) => {
                      setDispatchData((prevData) => ({
                        ...prevData,
                        date: e.target.value,
                      }));
                    }}
                    className="form-control"
                  />
                  <br />
                  <input
                    type="number"
                    placeholder="Paid Amount"
                    value={dispatchData.amount}
                    onChange={(e) => {
                      setDispatchData((prevData) => ({
                        ...prevData,
                        amount: e.target.value,
                      }));
                    }}
                    className="form-control"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setDispatchModal(false);
                    }}
                  >
                    Close
                  </Button>
                  <Button variant="contained" onClick={sendDispatchRequest}>
                    Mark as Paid
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                className="mt-5"
                show={adjAmountModal}
                onHide={() => {
                  setAdjAmountModal(false);
                  setAdjData({
                    advance: 0,
                    fine: 0,
                    eobi: 0,
                    allowance: 0,
                    arrears: 0,
                    bonus: 0,
                  });
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Increments or Decrements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex align-items-center gap-3">
                    <span style={{ minWidth: "5rem" }}>Allowance</span>
                    <input
                      type="number"
                      placeholder="Allowance"
                      value={ajdData.allowance}
                      onChange={(e) => {
                        setAdjData((prevData) => ({
                          ...prevData,
                          allowance: e.target.value,
                        }));
                      }}
                      className="form-control"
                    />
                  </div>
                  <br />
                  <div className="d-flex align-items-center gap-3">
                    <span style={{ minWidth: "5rem" }}>Arrears</span>
                    <input
                      type="number"
                      placeholder="Arrears"
                      value={ajdData.arrears}
                      onChange={(e) => {
                        setAdjData((prevData) => ({
                          ...prevData,
                          arrears: e.target.value,
                        }));
                      }}
                      className="form-control"
                    />
                  </div>
                  <br />
                  <div className="d-flex align-items-center gap-3">
                    <span style={{ minWidth: "5rem" }}>Bonus</span>
                    <input
                      type="number"
                      placeholder="Bonus"
                      value={ajdData.bonus}
                      onChange={(e) => {
                        setAdjData((prevData) => ({
                          ...prevData,
                          bonus: e.target.value,
                        }));
                      }}
                      className="form-control"
                    />
                  </div>
                  <br />
                  <div className="d-flex align-items-center gap-3">
                    <span style={{ minWidth: "5rem" }}>Advance</span>
                    <input
                      type="number"
                      placeholder="Advance"
                      value={ajdData.advance}
                      onChange={(e) => {
                        setAdjData((prevData) => ({
                          ...prevData,
                          advance: e.target.value,
                        }));
                      }}
                      className="form-control"
                    />
                  </div>
                  <br />
                  <div className="d-flex align-items-center gap-3">
                    <span style={{ minWidth: "5rem" }}>Fine</span>
                    <input
                      type="number"
                      placeholder="Fine"
                      value={ajdData.fine}
                      onChange={(e) => {
                        setAdjData((prevData) => ({
                          ...prevData,
                          fine: e.target.value,
                        }));
                      }}
                      className="form-control"
                    />
                  </div>
                  <br />
                  <div className="d-flex align-items-center gap-3">
                    <span style={{ minWidth: "5rem" }}>EOBI</span>
                    <input
                      type="number"
                      placeholder="EOBI"
                      value={ajdData.eobi}
                      onChange={(e) => {
                        setAdjData((prevData) => ({
                          ...prevData,
                          eobi: e.target.value,
                        }));
                      }}
                      className="form-control"
                    />
                  </div>
                  <br />
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setAdjAmountModal(false);
                      setAdjData({
                        advance: 0,
                        fine: 0,
                        eobi: 0,
                        allowance: 0,
                        arrears: 0,
                        bonus: 0,
                      });
                    }}
                  >
                    Close
                  </Button>
                  <Button variant="contained" onClick={sendAdjAmountRequest}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          ))}
          <div className="d-flex justify-content-center mt-4">
            <Pagination
              count={pageCount}
              page={page}
              onChange={(event, value) => {
                setPage(value);
              }}
              color="primary"
            />
          </div>
        </Row>
      ) : (
        <div className="spinner mx-auto m-5"></div>
      )}
    </div>
  );
}
