import { useState } from "react";
import { useLogin } from "../hooks/useLogin";
import {
  Alert,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import logo from "../assets/client_logo.png";
import illustration from "../assets/illustration/login_illustration.svg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await login(email, password);
  };

  return (
    <div className="loginpage">
      <form className="loginform" onSubmit={handleSubmit}>
        <Grid container justifyContent={"center"} mt={5} mb={2}>
          <img
            style={{
              width: "6rem",
            }}
            src={logo}
            alt="Logo"
          />
        </Grid>

        <Grid gap={3} alignItems={"center"} container>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            item
            xs={12}
            md={"auto"}
          >
            <img src={illustration} alt="Login Illustration" />
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderRadius: "4px",
              background: "#D3E2F4",
              mx: 5,
            }}
          />

          <Grid item display={"grid"} xs={12} md={"auto"} gap={3}>
            <TextField
              label="Email"
              type="email"
              value={email}
              size="small"
              inputProps={{
                style: {
                  color: "#082777",
                  fontFamily: "Poppins",
                },
              }}
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              type="password"
              size="small"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              inputProps={{
                style: {
                  color: "#082777",
                  fontFamily: "Poppins",
                },
              }}
              sx={{
                borderRadius: "8px",
                background: "#DDEAFB",
              }}
            />

            {error && <Alert severity="error">{error}</Alert>}

            <Button
              type="submit"
              sx={{
                my: 0,
                textTransform: "none",
                color: "#F3F8FF",
                fontFamily: "Poppins",
                borderRadius: "8px",
                background: "rgba(247, 172, 37)",
                boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.16) inset",
                "&:hover": {
                  background: "rgba(247, 172, 37, 0.56)",
                },
              }}
              className="btn btn-success my-4"
              disabled={isLoading}
            >
              Log in
            </Button>
          </Grid>
        </Grid>
      </form>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          boxShadow: "rgba(0, 0, 0, 0.8) 0 0 90px 33px",
        }}
        className="bg-dark text-light px-5 rounded-top"
      >
        Raatib - Product by Devaxiss
      </div>
    </div>
  );
};

export default Login;
