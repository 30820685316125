import React, { useState } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Grid,
  TextField,
} from "@mui/material";

const ViewAttendance = ({ setView }) => {
  const [selectedViewDate, setSelectedViewDate] = useState("");
  const [attendance, setAttendance] = useState([]);
  const [isloading, setisloading] = useState(false);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuthContext();

  const viewAttendance = async () => {
    if (selectedViewDate) {
      setisloading(true);
      await axios
        .get(`${baseUrl}/attendance/${selectedViewDate}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((response) => {
          setAttendance(
            response.data.sort((a, b) => (a.empID < b.empID ? -1 : 1))
          );
          console.log(attendance);
        })
        .catch((error) => {
          console.error("Error fetching attendance:", error);
        });
    }
    setisloading(false);
  };

  return (
    <>
      {isloading && <div className="spinner mx-auto m-5"></div>}
      {!isloading && (
        <div className="container">
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={3.5}
          >
            <Grid item>
              <Grid container gap={3.5}>
                <Grid item xs={12} md={7}>
                  <TextField
                    size="small"
                    onChange={(e) => {
                      setSelectedViewDate(e.target.value);
                    }}
                    type="date"
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={viewAttendance}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setView(false);
                }}
              >
                Add Attendance
              </Button>
            </Grid>
          </Grid>

          {attendance.length > 0 ? (
            <TableContainer sx={{ mt: 4 }} component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Employee ID</TableCell>
                    <TableCell>Employee Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Overtime (Hours)</TableCell>
                    <TableCell>Double OT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendance.map((record) => (
                    <TableRow key={record._id}>
                      <TableCell>{record.employee?.empID}</TableCell>
                      <TableCell>
                        <Link
                          className="text-decoration-none p-0 m-0"
                          to={`/attendance/${record?.employee?._id}`}
                        >
                          {record.employee ? record.employee.name : ""}
                        </Link>
                      </TableCell>
                      <TableCell>{record.status}</TableCell>
                      <TableCell>{record.overTime}</TableCell>
                      <TableCell>{record.doubleOT ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <p>No attendance records found for the selected date.</p>
          )}
        </div>
      )}{" "}
    </>
  );
};

export default ViewAttendance;
