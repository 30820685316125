import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import bankList from "../assets/mockData/bankList.json";
import cityList from "../assets/mockData/cityList.json";
import { useAuthContext } from "../hooks/useAuthContext";
import InputMask from "react-input-mask";
import addEmpIllustration from "../assets/illustration/addEmpIllustration.svg";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

export default function Addemployee() {
  const { state } = useLocation();
  const oldEmployee = state?.employee;
  console.log("Old: ", oldEmployee);
  const [isloading, setisloading] = useState(false);
  const [name, setName] = useState(oldEmployee?.name || null);
  const [fathername, setFathername] = useState(
    oldEmployee?.father_name || null
  );
  const [cnic, setCnic] = useState(oldEmployee?.cnic || null);
  const [location, setLocation] = useState(oldEmployee?.location || null);
  const [province, setProvice] = useState(oldEmployee?.province || null);
  const [city, setCity] = useState(oldEmployee?.city || null);
  const [empStatus, setEmpStatus] = useState(oldEmployee?.empStatus || null);
  const [designation, setDesignation] = useState(
    oldEmployee?.designation || null
  );
  const [gender, setGender] = useState(oldEmployee?.gender || null);
  const [empID, setEmpId] = useState(oldEmployee?.empID || null);
  const [phone, setPhone] = useState(oldEmployee?.phone || null);
  const [joindate, setJoindate] = useState(
    oldEmployee?.join_date
      ? new Date(oldEmployee?.join_date).toISOString().split("T")[0]
      : null
  );
  const [resigndate, setResignDate] = useState(
    oldEmployee?.resign_date || null
  );
  const [salary, setSalary] = useState(oldEmployee?.salary || null);
  const [salaryAccount, setSalaryAccount] = useState(
    oldEmployee?.salaryAccount || null
  );
  const [bankName, setBankName] = useState(oldEmployee?.bankName || null);
  const [bankAcc, setBankAcc] = useState(oldEmployee?.bankAcc || null);
  const [err, setErr] = useState(null);
  const [filteredCities, setFilteredCities] = useState([]);

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  function getCitiesByProvince(province) {
    var matchingCities = cityList.filter((city) => city.province === province);
    return matchingCities.map((city) => city.name);
  }

  useEffect(() => {
    setFilteredCities([]);
    setFilteredCities(getCitiesByProvince(province));
  }, [province]);

  useEffect(() => {
    setBankName("");
    setBankAcc("");
  }, [salaryAccount]);

  const provinceNames = [
    "Capital",
    "Punjab",
    "Sindh",
    "Khyber Pakhtunkhwa",
    "Balochistan",
  ];

  const navigate = useNavigate();

  const postform = async () => {
    setisloading(true);
    if (empStatus == "Resigned" && !resigndate) {
      alert("Please enter resignation date.");
      setisloading(false);
      return;
    }
    let response;
    if (oldEmployee) {
      response = await axios.put(
        `${baseUrl}/employees/${oldEmployee._id}`,
        {
          empID: empID,
          name: name,
          father_name: fathername,
          gender: gender,
          designation: designation,
          cnic: cnic,
          location: location,
          empStatus: empStatus,
          province: province,
          city: city,
          phone: phone,
          join_date: joindate,
          resign_date: empStatus === "Resigned" ? resigndate : null,
          salary: salary,
          salaryAccount: salaryAccount,
          bankName: bankName,
          bankAcc: bankAcc,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    } else {
      response = await axios.post(
        `${baseUrl}/employees`,
        {
          empID: empID,
          name: name,
          father_name: fathername,
          gender: gender,
          designation: designation,
          cnic: cnic,
          location: location,
          empStatus: empStatus,
          province: province,
          city: city,
          phone: phone,
          join_date: joindate,
          resign_date: empStatus === "Resigned" ? resigndate : null,
          salary: salary,
          salaryAccount: salaryAccount,
          bankName: bankName,
          bankAcc: bankAcc,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    }
    if (response.data.error) {
      setErr(response.data.error);
    } else {
      navigate("/");
    }
    setisloading(false);
  };

  return (
    <>
      {isloading && <div className="spinner mx-auto m-5"></div>}
      {err && (
        <div className="alert alert-danger w-50 mx-auto text-center">
          <h4>{err}</h4>
        </div>
      )}
      {!isloading && (
        <Box
          sx={{
            px: { xs: 0, md: 5, lg: 10 },
          }}
        >
          <img
            src={addEmpIllustration}
            alt="Background"
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              width: "30vw",
              minWidth: 200,
              zIndex: 0,
            }}
          />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              postform();
            }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={3}
            >
              <Typography
                fontSize={{ xs: 26, sm: 28, md: 32, lg: 34 }}
                variant="h4"
              >
                Add Employee
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/employees");
                }}
              >
                View Employees
              </Button>
            </Grid>
            <Typography
              sx={{
                color: "#071C50",
                fontSize: 24,
                fontWeight: 600,
              }}
              variant="h5"
            >
              Personal Detaills
            </Typography>
            <Grid container gap={3} mb={3} mt={1.5}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <TextField
                  fullWidth
                  required
                  color="secondary"
                  size="small"
                  label="Full Name"
                  value={name}
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  required
                  label="Father Name"
                  value={fathername}
                  type="text"
                  onChange={(e) => {
                    setFathername(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container gap={3} my={3}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <FormControl fullWidth color="secondary">
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    required
                    size="small"
                    labelId="gender-label"
                    label="Gender"
                    fullWidth
                    value={gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <MenuItem value="" hidden disabled>
                      Select Gender
                    </MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <InputMask
                  mask="9999-9999999"
                  value={phone}
                  onChange={(e) => {
                    const formattedPhone = e.target.value.replace(/-/g, "");
                    setPhone(formattedPhone);
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      fullWidth
                      color="secondary"
                      size="small"
                      label="Phone"
                      required
                      type="text"
                      maxLength="12"
                      minLength="12"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={5} md={3.5} lg={3}>
                <InputMask
                  mask="99999-9999999-9"
                  value={cnic}
                  onChange={(e) => {
                    const formattedCnic = e.target.value.replace(/-/g, "");
                    setCnic(formattedCnic);
                  }}
                >
                  {(inputProps) => (
                    <TextField
                      fullWidth
                      color="secondary"
                      size="small"
                      label="CNIC"
                      required
                      maxLength="15"
                      minLength="15"
                      type="text"
                      {...inputProps}
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
            <Typography
              sx={{
                color: "#071C50",
                fontSize: 24,
                fontWeight: 600,
              }}
              variant="h5"
              mt={6}
            >
              Professional Details
            </Typography>
            <Grid container gap={3} mb={3} mt={1.5}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  label="Employee ID"
                  value={empID}
                  required
                  type="number"
                  onChange={(e) => {
                    setEmpId(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container gap={3} my={3}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <FormControl fullWidth color="secondary">
                  <InputLabel id="province-label">Province</InputLabel>
                  <Select
                    required
                    size="small"
                    labelId="province-label"
                    label="Province"
                    fullWidth
                    value={province}
                    onChange={(e) => {
                      setProvice(e.target.value);
                    }}
                  >
                    <MenuItem value="" disabled hidden selected>
                      Provice
                    </MenuItem>
                    {provinceNames &&
                      provinceNames.map((prov) => (
                        <MenuItem key={prov} value={prov}>
                          {prov}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <FormControl fullWidth color="secondary">
                  <InputLabel id="city-label">City</InputLabel>
                  <Select
                    required
                    disabled={!province}
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    size="small"
                    labelId="city-label"
                    label="City"
                    fullWidth
                  >
                    <MenuItem value="" disabled hidden selected>
                      City
                    </MenuItem>
                    {filteredCities &&
                      filteredCities.map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={5} md={3.5} lg={3}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  label="Location"
                  value={location}
                  required
                  type="text"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container gap={3} my={3}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <FormControl fullWidth color="secondary">
                  <InputLabel id="salacc-label">Salary Account</InputLabel>
                  <Select
                    required
                    size="small"
                    color="secondary"
                    labelId="salacc-label"
                    label="Salary Account"
                    value={salaryAccount}
                    onChange={(e) => {
                      setSalaryAccount(e.target.value);
                    }}
                    class="form-select w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1 mx-auto"
                  >
                    <MenuItem value="" disabled hidden selected>
                      Salary Account
                    </MenuItem>
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Bank">Bank</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {salaryAccount === "Bank" && (
                <>
                  <Grid item xs={12} sm={5} md={3} lg={2.5}>
                    <FormControl fullWidth color="secondary">
                      <InputLabel id="bank-label">Bank</InputLabel>
                      <Select
                        required
                        size="small"
                        color="secondary"
                        labelId="salacc-label"
                        label="Salary Account"
                        value={bankName}
                        onChange={(e) => {
                          setBankName(e.target.value);
                        }}
                      >
                        <MenuItem value="" disabled hidden selected>
                          Bank Name
                        </MenuItem>
                        {bankList &&
                          bankList.map((bank) => (
                            <MenuItem key={bank} value={bank}>
                              {bank}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={5} md={3.5} lg={3}>
                    <TextField
                      fullWidth
                      color="secondary"
                      size="small"
                      label="Account Number"
                      required
                      value={bankAcc}
                      type="text"
                      onChange={(e) => {
                        setBankAcc(e.target.value);
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container gap={3} my={3}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  label="Designation"
                  value={designation}
                  required
                  type="text"
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <FormControl fullWidth color="secondary">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    required
                    size="small"
                    color="secondary"
                    labelId="status-label"
                    label="Status"
                    value={empStatus}
                    onChange={(e) => {
                      setEmpStatus(e.target.value);
                    }}
                  >
                    <MenuItem value="" disabled selected hidden>
                      Status
                    </MenuItem>
                    <MenuItem value="Currently Working">
                      Currently Working
                    </MenuItem>
                    <MenuItem value="Resigned">Resigned</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {empStatus === "Resigned" ? (
                <Grid item xs={12} sm={5} md={3.5} lg={3}>
                  <TextField
                    fullWidth
                    color="secondary"
                    size="small"
                    required
                    type="date"
                    value={resigndate}
                    helperText="Resignation Date"
                    onChange={(e) => {
                      setResignDate(e.target.value);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={5} md={3.5} lg={3}>
                  <TextField
                    fullWidth
                    color="secondary"
                    size="small"
                    required={!oldEmployee}
                    value={joindate}
                    type="date"
                    helperText="Joining Date"
                    onChange={(e) => {
                      setJoindate(e.target.value);
                    }}
                  />
                </Grid>
              )}
            </Grid>

            <Grid container gap={3} mt={-3} mb={5}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  label="Salary"
                  value={salary}
                  required
                  type="number"
                  onChange={(e) => {
                    setSalary(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Button variant="contained" color="secondary" type="submit">
              Add Employee
            </Button>
          </form>
        </Box>
      )}
    </>
  );
}
