import React from "react";
import ReactDOM from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material";
import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";

// Create MUI theme
const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // You can set your desired text transform here
        },
      },
    },
  },
  input: {
    borderRadius: "8px",
    background: "#DDEAFB",
  },
  palette: {
    primary: {
      main: "#474747",
    },
    secondary: {
      main: "#f65c0d",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
