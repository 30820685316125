import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";

export default function AddInvoice() {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState("");
  const [invoiceNumber, setinvoiceNumber] = useState("");
  const [clientList, setClientList] = useState([]);
  const [client, setClient] = useState("");
  const [amountBFT, setAmountBFT] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [amountAFT, setAmountAFT] = useState(0);
  const [err, setErr] = useState(null);

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const postForm = async () => {
    setIsLoading(true);
    const response = await axios.post(
      `${baseUrl}/invoices`,
      {
        invoiceNumber: invoiceNumber,
        createDate: date,
        taxAmount: taxAmount,
        amountAFT: amountAFT,
        client: client,
        amountBFT: amountBFT,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    if (response.data.error) {
      setErr(response.data.error);
    } else {
      window.location.reload();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getClientList = async () => {
      await axios
        .get(`${baseUrl}/customers`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((res) => {
          setClientList(res.data);
        })
        .finally(() => {
          console.log(clientList);
        });
    };
    getClientList();
  }, []);

  useEffect(() => {
    const newAmount =
      parseInt(amountBFT) + parseFloat((amountBFT * taxAmount) / 100);
    setAmountAFT(newAmount);
  }, [amountBFT, taxAmount]);

  return (
    <>
      {isLoading && <div className="spinner mx-auto m-5"></div>}
      {err && (
        <div className="alert alert-danger w-50 mx-auto text-center">
          <h4>{err}</h4>
        </div>
      )}
      {!isLoading && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            postForm();
          }}
          className="mx-auto text-center"
        >
          <br />
          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Invoice Number"
            type="text"
            onChange={(e) => {
              setinvoiceNumber(e.target.value);
            }}
          />
          <br />

          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Date"
            type="date"
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
          <br />

          <select
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Client"
            onChange={(e) => {
              setClient(e.target.value);
            }}
          >
            <option defaultValue={""} hidden value={""}>
              Client
            </option>
            {clientList?.map((customer) => (
              <option key={customer.name} value={customer.name}>
                {customer.name}
              </option>
            ))}
          </select>

          <br />

          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Amount before tax"
            type="number"
            step={0.02}
            onChange={(e) => {
              setAmountBFT(e.target.value);
            }}
          />
          <br />
          <input
            className="w-50 border-bottom my-2 border-0 border-secondary px-3 pt-3 pb-1"
            required
            placeholder="Tax Percentage"
            type="number"
            step={0.02}
            onChange={(e) => {
              setTaxAmount(e.target.value);
            }}
          />

          <h6 className="text-success mt-4 mb-5">
            Amount after tax: {amountAFT}
          </h6>

          <input
            className="btn mb-5 btn-primary w-50 rounded-5 fs-4 pb-1"
            type="submit"
            value="Add Invoice"
          />
        </form>
      )}
    </>
  );
}
