import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Link as MuiLink,
  Grid,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AppRegistration,
} from "@mui/icons-material";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";

export default function EmployeeCard({ employee }) {
  const navigate = useNavigate();

  return (
    <Card
      sx={{ p: 2, pb: 0, borderRadius: "8px", background: "#F3F8FF" }}
      elevation={0}
    >
      <CardContent sx={{ p: 0 }}>
        <Link
          style={{ textDecoration: "none", margin: 0, padding: 0 }}
          to={`/employees/${employee._id}`}
        >
          <Typography
            sx={{
              color: "#071C50",
              fontSize: 24,
              fontWeight: 600,
            }}
            variant="h5"
          >
            {employee.name}
          </Typography>
        </Link>
        <Typography component="small" sx={{ fontSize: "1rem" }}>
          {employee.gender === "Male" ? "s/o" : "d/o"} {employee.father_name}
        </Typography>

        <Typography mt={2} fontWeight={500} color="secondary">
          {employee.empID}
        </Typography>
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mt={2}
        >
          <Typography variant="body2">
            {employee.designation}, {employee.city}
          </Typography>
          <div>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                navigate("/employees/addEmployee", { state: { employee } });
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
            <Link
              style={{ textDecoration: "none" }}
              to={`/attendance/${employee?._id}`}
            >
              <IconButton size="small">
                <AppRegistration color="secondary" />
              </IconButton>
            </Link>
          </div>
        </Grid>
      </CardContent>
    </Card>
  );
}
