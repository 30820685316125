import React, { useState } from "react";
import axios from "axios";
import expenses from "../assets/mockData/expenseList.json";
import { useAuthContext } from "../hooks/useAuthContext";
import addVoucherIllustration from "../assets/illustration/addVoucherIllustration.svg";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AddVoucher() {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [account, setAccount] = useState("");
  const [debitAmount, setDebitAmount] = useState("");
  const [category, setCategory] = useState(null);
  const [location, setLocation] = useState("Capital");
  const [creditAmount, setCreditAmount] = useState("");
  const [err, setErr] = useState(null);

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const postForm = async () => {
    setIsLoading(true);
    const response = await axios.post(
      `${baseUrl}/vouchers`,
      {
        date: date,
        description: description,
        category: category,
        location: location,
        account: account,
        debitAmount: debitAmount,
        creditAmount: creditAmount,
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    if (response.data.error) {
      setErr(response.data.error);
    } else {
      navigate("/vouchers");
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <div className="spinner mx-auto m-5"></div>}
      {err && (
        <div className="alert alert-danger w-50 mx-auto text-center">
          <h4>{err}</h4>
        </div>
      )}
      {!isLoading && (
        <Box
          sx={{
            px: { xs: 0, md: 5, lg: 10 },
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              postForm();
            }}
          >
            <img
              src={addVoucherIllustration}
              alt="Background"
              style={{
                position: "fixed",
                bottom: 0,
                right: 0,
                width: "40vw",
                minWidth: 280,
                zIndex: 0,
              }}
            />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={3}
            >
              <Typography
                fontSize={{ xs: 26, sm: 28, md: 32, lg: 34 }}
                variant="h4"
              >
                Add Voucher
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/vouchers");
                }}
              >
                View Vouchers
              </Button>
            </Grid>

            <Typography
              sx={{
                color: "#071C50",
                fontSize: 24,
                fontWeight: 600,
              }}
              variant="h5"
            >
              Voucher Detaills
            </Typography>

            <Grid container gap={3} my={4}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <FormControl fullWidth color="secondary">
                  <InputLabel id="cat-label">Category</InputLabel>
                  <Select
                    required
                    size="small"
                    labelId="cat-label"
                    label="Categry"
                    fullWidth
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <MenuItem value={null} disabled selected hidden>
                      Category
                    </MenuItem>
                    {expenses?.map((expense) => (
                      <MenuItem value={expense}>{expense}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <FormControl fullWidth color="secondary">
                  <InputLabel id="loc-label">Location</InputLabel>
                  <Select
                    required
                    size="small"
                    labelId="loc-label"
                    label="Location"
                    fullWidth
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  >
                    <MenuItem disabled hidden selected>
                      Location
                    </MenuItem>
                    <MenuItem value="Capital">Capital</MenuItem>
                    <MenuItem value="Lahore">Lahore</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={3.5} lg={3}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  required
                  type="date"
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container gap={3} my={4}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  label="Description"
                  required
                  type="text"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  label="Account"
                  required
                  type="text"
                  onChange={(e) => {
                    setAccount(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container gap={3} my={4}>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  label="Debit Amount"
                  required
                  type="number"
                  inputProps={{
                    step: 0.01,
                  }}
                  onChange={(e) => {
                    setDebitAmount(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={3} lg={2.5}>
                <TextField
                  fullWidth
                  color="secondary"
                  size="small"
                  label="Credit Amount"
                  required
                  type="number"
                  inputProps={{
                    step: 0.01,
                  }}
                  onChange={(e) => {
                    setCreditAmount(e.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Button variant="contained" color="secondary" type="submit">
              Add Voucher
            </Button>
          </form>
        </Box>
      )}
    </>
  );
}
