import React, { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import EmployeeCard from "./EmployeeCard";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

export default function EmployeeList() {
  const [isloading, setisloading] = useState(false);

  const [emps, setEmps] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState("empID");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    fetchEmployees();
  }, [page]);

  const fetchEmployees = async () => {
    setisloading(true);
    await axios
      .get(
        `${baseUrl}/employees?page=${page}&limit=12&searchCriteria=${searchCriteria}&searchQuery=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((response) => {
        setEmps(response.data.docs || response.data);
        setPageCount(response.data.totalPages);
        console.log(emps);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      });
    setisloading(false);
  };

  const deleteEmp = (empId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this employee?"
    );
    if (confirmDelete) {
      setisloading(true);
      axios
        .delete(`${baseUrl}/employees/${empId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(() => {
          fetchEmployees();
        })
        .catch((error) => {
          console.error("Error deleting employee:", error);
        });
    }
    setisloading(false);
  };

  const handleSearchCriteriaChange = (event) => {
    setSearchCriteria(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      {isloading && <div className="spinner mx-auto m-5"></div>}
      {!isloading && (
        <Box sx={{ px: { xs: 0, md: 5, lg: 10 } }}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={3.5}
          >
            <Grid item>
              <form onSubmit={fetchEmployees}>
                <FormGroup row>
                  <Select
                    size="small"
                    sx={{
                      borderRadius: "7px 0px 0px 7px",
                    }}
                    id="searchCriteria"
                    name="searchCriteria"
                    color="secondary"
                    value={searchCriteria}
                    onChange={handleSearchCriteriaChange}
                  >
                    <MenuItem value="empID">ID</MenuItem>
                    <MenuItem value="name">Name</MenuItem>
                    <MenuItem value="father_name">Father Name</MenuItem>
                    <MenuItem value="cnic">CNIC</MenuItem>
                    <MenuItem value="phone">Phone No.</MenuItem>
                  </Select>
                  <TextField
                    size="small"
                    sx={{
                      borderRadius: 0,
                    }}
                    variant="outlined"
                    placeholder="Search"
                    color="secondary"
                    type="text"
                    id="searchQuery"
                    name="searchQuery"
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                  />
                  <Button
                    size="small"
                    sx={{
                      borderRadius: "0px 7px 7px 0px",
                    }}
                    disableElevation
                    color="secondary"
                    variant="contained"
                    type="submit"
                  >
                    <SearchIcon />
                  </Button>
                </FormGroup>
              </form>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/employees/addEmployee");
                }}
              >
                Add Employee
              </Button>
            </Grid>
          </Grid>

          <Grid container justifyContent={"space-between"}>
            {emps?.map((employee) => (
              <Grid key={employee._id} item xs={12} md={5.5} lg={2.5} mb={2.5}>
                <EmployeeCard employee={employee} deleteEmp={deleteEmp} />
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent={"center"}>
            <Pagination
              count={pageCount}
              page={page}
              onChange={(event, value) => {
                setPage(value);
              }}
              color="primary"
            />
          </Grid>
        </Box>
      )}
    </>
  );
}
