import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function CustomerList() {
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState({
    id: "",
    name: "",
    address: "",
    personInContact: "",
    phone: "",
    email: "",
  });
  const [searchCriteria, setSearchCriteria] = useState("name");
  const [searchQuery, setSearchQuery] = useState("");

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/customers`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
    setIsLoading(false);
  };

  const deleteCustomer = (customerId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this customer?"
    );
    if (confirmDelete) {
      setIsLoading(true);
      axios
        .delete(`${baseUrl}/customers/${customerId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(() => {
          setCustomers((prevCustomers) =>
            prevCustomers.filter((customer) => customer._id !== customerId)
          );
        })
        .catch((error) => {
          console.error("Error deleting customer:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const editCustomer = (customer) => {
    setEditedCustomer({
      id: customer._id,
      name: customer.name,
      address: customer.address,
      personInContact: customer.personInContact,
      phone: customer.phone,
      email: customer.e_mail,
    });
    setEdit(true);
  };

  const cancelEdit = () => {
    setEdit(false);
    setEditedCustomer({
      id: "",
      name: "",
      address: "",
      personInContact: "",
      phone: "",
      email: "",
    });
  };

  const saveEdit = () => {
    setIsLoading(true);
    const { id, name, address, personInContact, phone, email } = editedCustomer;
    axios
      .put(
        `${baseUrl}/customers/${id}`,
        {
          name,
          address,
          personInContact,
          phone,
          e_mail: email,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(() => {
        fetchCustomers();
        setEdit(false);
        setEditedCustomer({
          id: "",
          name: "",
          address: "",
          personInContact: "",
          phone: "",
          email: "",
        });
      })
      .catch((error) => {
        console.error("Error saving customer:", error);
      })
      .finally(() => {
        setIsLoading(false);
        setEdit(false);
        fetchCustomers();
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));
  };

  const sortByColumn = (column) => {
    const sortedCustomers = [...customers];
    sortedCustomers.sort((a, b) => {
      if (a[column] < b[column]) return -1;
      if (a[column] > b[column]) return 1;
      return 0;
    });
    setCustomers(sortedCustomers);
  };

  const handleSearchCriteriaChange = (event) => {
    setSearchCriteria(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchCustomers = () => {
    let filteredCustomers = customers;

    if (searchQuery) {
      const searchKey = searchQuery.toLowerCase();
      filteredCustomers = customers.filter((customer) =>
        customer[searchCriteria].toLowerCase().includes(searchKey)
      );
    }

    return filteredCustomers;
  };

  const filteredCustomers = searchCustomers();

  return (
    <>
      {isLoading && <div className="spinner mx-auto m-5"></div>}
      {!isLoading && (
        <div className="container">
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={3.5}
          >
            <Grid item>
              <FormGroup row>
                <Select
                  size="small"
                  sx={{
                    borderRadius: "7px 0px 0px 7px",
                  }}
                  id="searchCriteria"
                  name="searchCriteria"
                  color="secondary"
                  value={searchCriteria}
                  onChange={handleSearchCriteriaChange}
                >
                  <MenuItem value="name">Name</MenuItem>
                  <MenuItem value="address">Address</MenuItem>
                  <MenuItem value="phone">Phone No.</MenuItem>
                  <MenuItem value="e_mail">Email</MenuItem>
                  <MenuItem value="personInContact">Person in contact</MenuItem>
                </Select>
                <TextField
                  size="small"
                  sx={{
                    borderRadius: 0,
                  }}
                  variant="outlined"
                  placeholder="Search"
                  color="secondary"
                  type="text"
                  id="searchQuery"
                  name="searchQuery"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/customers/add");
                }}
              >
                Add Customer
              </Button>
            </Grid>
          </Grid>
          <div className="row mt-4">
            {!edit && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell onClick={() => sortByColumn("name")}>
                        Name
                      </TableCell>
                      <TableCell onClick={() => sortByColumn("address")}>
                        Address
                      </TableCell>
                      <TableCell onClick={() => sortByColumn("phone")}>
                        Phone No.
                      </TableCell>
                      <TableCell onClick={() => sortByColumn("email")}>
                        Email
                      </TableCell>
                      <TableCell
                        onClick={() => sortByColumn("personInContact")}
                      >
                        Contact person
                      </TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredCustomers.map((customer) => (
                      <TableRow key={customer._id}>
                        <TableCell>{customer.name}</TableCell>
                        <TableCell>{customer.address}</TableCell>
                        <TableCell>{customer.phone}</TableCell>
                        <TableCell>{customer.e_mail}</TableCell>
                        <TableCell>{customer.personInContact}</TableCell>
                        <TableCell className="p-0">
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              setEdit(true);
                              editCustomer(customer);
                            }}
                          >
                            <EditOutlined />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              deleteCustomer(customer._id);
                            }}
                          >
                            <DeleteOutline color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {edit && (
              <div>
                <h3>Edit Customer</h3>
                <form className="w-50">
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={editedCustomer.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="address" className="form-label">
                      address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      name="address"
                      value={editedCustomer.address}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="personInContact" className="form-label">
                      Person in contact
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="personInContact"
                      name="personInContact"
                      value={editedCustomer.personInContact}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      Phone No.
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      value={editedCustomer.phone}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={editedCustomer.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <button className="btn btn-primary mx-2" onClick={saveEdit}>
                    Save
                  </button>
                  <button
                    className="btn btn-secondary mx-2"
                    onClick={cancelEdit}
                  >
                    Cancel
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
