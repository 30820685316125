import axios from "axios";
import { useAuthContext } from "./useAuthContext";

export const useGet = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const userState = useAuthContext();

  const token = userState.token;

  const getUserById = async (id) => {
    const response = await axios.get(`${baseUrl}/user/find/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const user = response.data.user;
    return user;
  };

  return { getUserById };
};
