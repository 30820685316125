import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useAuthContext } from "../hooks/useAuthContext";
import IosShareIcon from "@mui/icons-material/IosShare";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InvoiceCard from "../components/InvoiceCard";
import { useNavigate } from "react-router-dom";

export default function InvoiceList() {
  const [invoices, setInvoices] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState("invoiceNumber");
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [recieveDate, setRecieveDate] = useState("");

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/invoices`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setInvoices(response.data.invoices);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
    setIsLoading(false);
  };

  const handleSearchCriteriaChange = (event) => {
    setSearchCriteria(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchInvoices = () => {
    if (!searchQuery) {
      return invoices;
    }

    const filteredInvoices = invoices.filter((invoice) => {
      const fieldValue = invoice[searchCriteria]?.toString()?.toLowerCase();
      const searchValue = searchQuery?.toString()?.toLowerCase();
      return fieldValue?.includes(searchValue);
    });

    return filteredInvoices;
  };

  const filteredInvoices = searchInvoices();

  const sendApproveRequest = async (num) => {
    setIsLoading(true);
    const res = await axios.put(
      `${baseUrl}/invoices/${num}`,
      {
        recieveDate: recieveDate ? recieveDate : new Date(),
      },
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    setIsLoading(false);
    if (res.status !== 200) {
      alert("Failed to approve invoice.");
    } else {
      fetchInvoices();
    }
  };

  const sendDeleteRequest = async (num, unapprove) => {
    setIsLoading(true);
    const res = await axios.delete(
      `${baseUrl}/invoices/${num}${
        unapprove ? "?unapprove=true" : "?unapprove=false"
      }`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    setIsLoading(false);
    if (res.status !== 200) {
      alert("Failed.");
    } else {
      fetchInvoices();
    }
  };

  const exportToExcel = () => {
    let exportData = [];

    invoices.forEach((data) => {
      exportData.push({
        Invoice_Number: data.invoiceNumber,
        Clinet: data.client,
        Created_at: data.createDate.toString().slice(0, 10),
        Amount_Before_Tax: data.amountBFT,
        Tax: `${data.taxAmount}%`,
        Amount_After_Tax: data.amountAFT,
        Payment_Recieved: data.isRecieved ? "Yes" : "no",
        Recieved_At: data.recieveDate
          ? data.recieveDate.toString().slice(0, 10)
          : "N/A",
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    const date = new Date();

    saveAs(blob, `invoices-${date}.xlsx`);
  };

  return (
    <>
      {isLoading && <div className="spinner mx-auto m-5"></div>}
      {!isLoading && (
        <>
          <div className="container">
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={3.5}
            >
              <Grid item>
                <FormGroup row>
                  <Select
                    size="small"
                    sx={{
                      borderRadius: "7px 0px 0px 7px",
                    }}
                    id="searchCriteria"
                    name="searchCriteria"
                    color="secondary"
                    value={searchCriteria}
                    onChange={handleSearchCriteriaChange}
                  >
                    <MenuItem value="invoiceNumber">Invoice Number</MenuItem>
                    <MenuItem value="client">Client</MenuItem>
                  </Select>
                  <TextField
                    size="small"
                    sx={{
                      borderRadius: 0,
                    }}
                    variant="outlined"
                    placeholder="Search"
                    color="secondary"
                    type="text"
                    id="searchQuery"
                    name="searchQuery"
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                  />
                  <Button
                    size="small"
                    sx={{
                      borderRadius: "0px 7px 7px 0px",
                    }}
                    disableElevation
                    color="secondary"
                    variant="contained"
                  >
                    <SearchIcon />
                  </Button>
                </FormGroup>
              </Grid>

              <Grid display={"flex"} gap={2} item>
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={exportToExcel}
                >
                  <IosShareIcon />
                </IconButton>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate("/invoices/add");
                  }}
                >
                  Add Invoice
                </Button>
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"}>
              {isLoading ? (
                <div className="spinner mx-auto m-5"></div>
              ) : (
                filteredInvoices.map((invoice) => (
                  <Grid
                    key={invoice._id}
                    item
                    xs={12}
                    md={5.5}
                    lg={2.5}
                    mb={2.5}
                  >
                    <InvoiceCard
                      invoice={invoice}
                      user={user}
                      sendDeleteRequest={sendDeleteRequest}
                      sendApproveRequest={sendApproveRequest}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
