import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import { Box, Button, Grid } from "@mui/material";
import dashboardImg1 from "../assets/illustration/dashboardImg1.svg";
import dashboardImg2 from "../assets/illustration/dashboardImg2.svg";
import dashboardImg3 from "../assets/illustration/dashboardImg3.svg";
import dashboardImg4 from "../assets/illustration/dashboardImg4.svg";
import dashboardImg5 from "../assets/illustration/dashboardImg5.svg";
import dashboardImg6 from "../assets/illustration/dashboardImg6.svg";
import dashboardImg7 from "../assets/illustration/dashboardImg7.svg";
import dashboardImg8 from "../assets/illustration/dashboardImg8.svg";
import DashboardCard from "../components/dashboard/DashboardCard";

export default function Dashboard() {
  const [isloading, setisloading] = useState(false);
  const [employeeCount, setEmployeeCount] = useState(null);
  const [totalSalary, setTotalSalary] = useState(null);
  const [pendingInvoices, setPendingInvoices] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [dateFilter, setDateFilter] = useState("");
  const [spent, setSpent] = useState(null);
  const [rcvd, setRcvd] = useState(null);
  const [pendingInvAmount, setPendingInvAmount] = useState("");
  const [recievedInvAmount, setRecievedInvAmount] = useState("");
  const [pendingVouchers, setPendingVouchers] = useState(0);

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchEmployeeStats = async () => {
    try {
      const response = await axios.get(`${baseUrl}/dashboard`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching employee stats:", error.response);
      throw error;
    }
  };

  const getEmployeeStatsByDate = async (month, year) => {
    try {
      const response = await axios.get(
        `${baseUrl}/dashboard/${month}/${year}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching employee stats by date:", error.response);
      throw error;
    }
  };

  useEffect(() => {
    const getEmployeeStats = async () => {
      setisloading(true);
      try {
        const stats = await fetchEmployeeStats();
        setEmployeeCount(stats.totalEmployees);
        setTotalSalary(stats.sumOfSalaries);
        setPendingInvoices(stats.pendingInvoices);
        setTotalClients(stats.totalClients);
        setPendingInvAmount(stats.pendingInvAmount);
        setRecievedInvAmount(stats.recievedInvAmount);
        setSpent(stats.paidThisMonth);
        setRcvd(stats.receivedThisMonth);
        setPendingVouchers(stats.pendingVouchers);
      } catch (error) {
        // Handle error if needed
      }
      setisloading(false);
    };

    getEmployeeStats();
  }, []);

  const handleSearch = async () => {
    if (dateFilter) {
      const selectedDate = new Date(dateFilter);
      const selectedMonth = selectedDate.getMonth() + 1; // Adjusting for zero-based month
      const selectedYear = selectedDate.getFullYear();

      setisloading(true);
      try {
        const stats = await getEmployeeStatsByDate(selectedMonth, selectedYear);
        setEmployeeCount(stats.totalEmployees);
        setTotalSalary(stats.sumOfSalaries);
        setPendingInvoices(stats.pendingInvoices);
        setTotalClients(stats.totalClients);
        setPendingInvAmount(stats.pendingInvAmount);
        setRecievedInvAmount(stats.recievedInvAmount);
        setSpent(stats.paidThisMonth);
        setRcvd(stats.receivedThisMonth);
        setPendingVouchers(stats.pendingVouchers);
      } catch (error) {
        // Handle error if needed
      }
      setisloading(false);
    }
  };

  const dashboardData = [
    {
      title: "Number Of Employees",
      value: employeeCount,
      pic: dashboardImg1,
    },
    {
      title: "Number Of Clients",
      value: totalClients,
      pic: dashboardImg2,
    },
    {
      title: "Total Payable Salary",
      value: totalSalary,
      pic: dashboardImg4,
    },
    {
      title: "Pending Invoices",
      value: pendingInvoices,
      pic: dashboardImg3,
    },
    {
      title: "Pending Vouchers",
      value: pendingVouchers,
      pic: dashboardImg1,
    },
    {
      title: "Recieved This Month",
      value: rcvd,
      pic: dashboardImg5,
    },
    {
      title: "Spent This Month",
      value: spent,
      pic: dashboardImg7,
    },
    {
      title: "Pending Amount (Invoices)",
      value: pendingInvAmount,
      pic: dashboardImg8,
    },

    {
      title: "Amount Recieved (Invoices)",
      value: recievedInvAmount,
      pic: dashboardImg2,
    },
  ];

  return (
    <>
      {isloading ? (
        <div className="spinner mx-auto m-5"></div>
      ) : (
        <Box sx={{ px: { xs: 0, md: 5, lg: 10 } }}>
          <Grid container gap={3.5}>
            <Grid item xs={12} md={2}>
              <input
                onChange={(e) => {
                  setDateFilter(e.target.value);
                }}
                type="date"
                className="form-control"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          <Grid container mt={3} gap={8} justifyContent={"space-between"}>
            {dashboardData.map((data, i) => (
              <DashboardCard
                key={i}
                value={data.value}
                title={data.title}
                pic={data.pic}
              />
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}
