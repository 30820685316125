import React, { useState, useCallback } from "react";
import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  Container,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  Button,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

const RecordAttendance = ({ setView }) => {
  const [employees, setEmployees] = useState([]);
  const [attendanceRecords, setAttendanceRecords] = useState([]);
  const [selectedRecordDate, setSelectedRecordDate] = useState("");
  const [error, setError] = useState(null); // New state for error message
  const [isloading, setisloading] = useState(false);

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchEmployees = useCallback(() => {
    setisloading(true);
    console.log(selectedRecordDate);
    axios
      .get(
        `${baseUrl}/employees?limit=all&page=1&empStatus=working&attendanceDay=${selectedRecordDate}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((response) => {
        setEmployees(response.data.docs);
        initializeAttendanceRecords(response.data.docs);
      })
      .catch((error) => {
        console.error("Error fetching employees:", error);
      })
      .finally(() => {
        setisloading(false);
      });
  }, [employees, selectedRecordDate]);

  const initializeAttendanceRecords = (employees) => {
    const initialRecords = employees.map((employee) => ({
      employeeId: employee._id,
      status: "",
      overTime: "",
      doubleOT: false,
    }));

    setAttendanceRecords(initialRecords);
  };

  const handleStatusChange = (event, employeeId) => {
    const updatedRecords = attendanceRecords.map((record) => {
      if (record.employeeId === employeeId) {
        return {
          ...record,
          status: event.target.value,
        };
      }
      return record;
    });

    setAttendanceRecords(updatedRecords);
  };

  const handleWorkingHoursChange = (event, employeeId) => {
    const updatedRecords = attendanceRecords.map((record) => {
      if (record.employeeId === employeeId) {
        return {
          ...record,
          overTime: parseInt(event.target.value),
        };
      }
      return record;
    });

    setAttendanceRecords(updatedRecords);
  };

  const handleDoubleOTChange = (event, employeeId) => {
    const updatedRecords = attendanceRecords.map((record) => {
      if (record.employeeId === employeeId) {
        return {
          ...record,
          doubleOT: !record.doubleOT,
        };
      }
      return record;
    });

    setAttendanceRecords(updatedRecords);
  };

  const handleRecordDateChange = (event) => {
    setSelectedRecordDate(event.target.value);
  };

  const recordAttendance = () => {
    if (attendanceRecords.length > 0 && selectedRecordDate) {
      attendanceRecords.forEach((record) => {
        if (record.status) {
          const attendanceData = {
            employeeId: record.employeeId,
            date: selectedRecordDate,
            status: record.status,
            overTime: record.doubleOT ? record.overTime * 2 : record.overTime,
            doubleOT: record.doubleOT,
          };

          axios
            .post(`${baseUrl}/attendance/`, attendanceData, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then(() => {
              // Success message or further actions
            })
            .catch((error) => {
              setError(error.response.data.error); // Set the error message state
              console.error("Error recording attendance:", error);
            });
        }
      });

      // Clear the attendance records and selected date
      setAttendanceRecords([]);
      setSelectedRecordDate("");

      setView(true);
    }
  };

  return (
    <Container>
      <Typography variant="h6" component="div">
        Record Date
      </Typography>
      <Grid container justifyContent={"space-between"}>
        <Grid item display={"flex"}>
          <TextField
            type="date"
            id="recordDate"
            size="small"
            className="w-100"
            value={selectedRecordDate}
            onChange={handleRecordDateChange}
          />
          <IconButton onClick={fetchEmployees}>
            <SearchOutlined />
          </IconButton>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setView(true);
            }}
          >
            View Attendance
          </Button>
        </Grid>
      </Grid>

      {error && <div style={{ color: "red", marginBottom: 10 }}>{error}</div>}

      <TableContainer sx={{ mt: 4 }} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className="py-3">Name</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Time Adjustment (Hours)</TableCell>
              <TableCell>Double OT</TableCell>
            </TableRow>
          </TableHead>
          {isloading ? (
            <div className="spinner m-5"></div>
          ) : (
            <TableBody>
              {employees.map((employee) => (
                <TableRow key={employee._id}>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.empID}</TableCell>
                  <TableCell>
                    <FormControl size="small" sx={{ minWidth: 120 }}>
                      <InputLabel id={`status-label-${employee._id}`}>
                        Status
                      </InputLabel>
                      <Select
                        labelId={`status-label-${employee._id}`}
                        value={
                          attendanceRecords.find(
                            (record) => record.employeeId === employee._id
                          )?.status || ""
                        }
                        onChange={(event) =>
                          handleStatusChange(event, employee._id)
                        }
                      >
                        <MenuItem value="">Select Status</MenuItem>
                        <MenuItem value="present">Present</MenuItem>
                        <MenuItem value="absent">Absent</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      size="small"
                      value={
                        attendanceRecords.find(
                          (record) => record.employeeId === employee._id
                        )?.overTime || ""
                      }
                      onChange={(event) =>
                        handleWorkingHoursChange(event, employee._id)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      size="small"
                      onChange={(event) =>
                        handleDoubleOTChange(event, employee._id)
                      }
                      checked={employee.doubleOT}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        onClick={recordAttendance}
        sx={{ marginTop: 3 }}
      >
        Record Attendance
      </Button>
    </Container>
  );
};

export default RecordAttendance;
