import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Table } from "react-bootstrap";

export default function UserPage() {
  const [users, setUsers] = useState([]);
  const [addUserModal, setAddUserModal] = useState(false);
  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleAddUser = async (userData) => {
    try {
      await axios.post(`${baseUrl}/user/signup`, userData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setAddUserModal(false);
      // Refresh the user list
      getUsers();
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(baseUrl + "/user/findall", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setUsers(response.data.users);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h2>List of Moderators</h2>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAddUserModal(true)}
        >
          Add Moderator
        </Button>
      </div>

      <Dialog open={addUserModal} onClose={() => setAddUserModal(false)}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <AddUserForm onSubmit={handleAddUser} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddUserModal(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <UserTable users={users} />
    </div>
  );
}

const AddUserForm = ({ onSubmit }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");

  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name, email, password, location });
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        type="text"
        value={name}
        onChange={(e) => {
          setError("");
          setName(e.target.value);
        }}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => {
          setError("");
          setEmail(e.target.value);
        }}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => {
          setError("");
          setPassword(e.target.value);
        }}
        fullWidth
        margin="normal"
        required
      />
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Location</InputLabel>
        <Select
          label="Location"
          value={location}
          onChange={(e) => {
            setError("");
            setLocation(e.target.value);
          }}
        >
          <MenuItem value="">Select Location</MenuItem>
          <MenuItem value="Capital">Capital</MenuItem>
          <MenuItem value="Punjab">Punjab</MenuItem>
          <MenuItem value="Sindh">Sindh</MenuItem>
          <MenuItem value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</MenuItem>
          <MenuItem value="Balochistan">Balochistan</MenuItem>
          <MenuItem value="Lahore">Lahore</MenuItem>
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary">
        Add User
      </Button>
    </form>
  );
};

const UserTable = ({ users }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Serial No.</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Is Admin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <TableRow key={user.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.location}</TableCell>
              <TableCell>{user.isAdmin ? "Yes" : "No"}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
