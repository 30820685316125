import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { useAuthContext } from "../hooks/useAuthContext";
import IosShareIcon from "@mui/icons-material/IosShare";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import VoucherCard from "../components/VoucherCard";

export default function VoucherList() {
  const [vouchers, setVouchers] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState("voucherNumber");
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const { user } = useAuthContext();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const exportToExcel = () => {
    let exportData = [];

    vouchers.forEach((data) => {
      exportData.push({
        Voucher_Number: data.voucherNumber,
        Date: data.date,
        Category: data.category,
        Location: data.location,
        Description: data.description,
        Status: data.isApproved,
        Account: data.account,
        Debit_Amount: data.debitAmount,
        Credit_Amount: data.creditAmount,
        Balance: data.balance,
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Buffer to store the generated Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    const date = new Date();

    saveAs(blob, `vouchers-${date}.xlsx`);
  };

  const printVoucher = async (vouch) => {
    // Create a new document with paragraphs
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            // Add a heading
            new Paragraph({
              text: "Voucher Details",
              heading: "Heading1",
              bold: true,
              size: 16,
            }),

            // Add heading-value pairs using tabs
            new Paragraph({
              children: [
                new TextRun({ text: "Voucher Number:", bold: true }),
                new TextRun("\t\t\t" + vouch.voucherNumber),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({ text: "Category:", bold: true }),
                new TextRun("\t\t\t\t" + vouch.category),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({ text: "Description:", bold: true }),
                new TextRun("\t\t\t\t" + vouch.description),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({ text: "Location:", bold: true }),
                new TextRun("\t\t\t\t" + vouch.location),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({ text: "Account:", bold: true }),
                new TextRun("\t\t\t\t" + vouch.account),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({ text: "Debit Amount:", bold: true }),
                new TextRun("\t\t\t\t" + vouch.debitAmount),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({ text: "Credit Amount:", bold: true }),
                new TextRun("\t\t\t\t" + vouch.creditAmount),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({ text: "Balance:", bold: true }),
                new TextRun("\t\t\t\t" + vouch.balance),
              ],
            }),

            new Paragraph({
              children: [
                new TextRun({ text: "Date:", bold: true }),
                new TextRun("\t\t\t\t\t" + vouch.date.slice(0, 10)),
              ],
            }),
          ],
        },
      ],
    });

    // Set metadata properties
    doc.title = `Voucher-${vouch.voucherNumber}`;
    doc.description = `${vouch.description}`;
    doc.creator = "SFM";

    try {
      // Use Packer.toBlob to get a Blob
      const blob = await Packer.toBlob(doc);

      // Save the Blob to a file using file-saver
      saveAs(blob, `Voucher-${vouch.voucherNumber}.docx`);
    } catch (error) {
      console.error("Error creating the document:", error);
    }
  };

  useEffect(() => {
    fetchVouchers();
  }, []);

  const fetchVouchers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/vouchers`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setVouchers(response.data.vouchers);
    } catch (error) {
      console.error("Error fetching vouchers:", error);
    }
    setIsLoading(false);
  };

  const handleSearchCriteriaChange = (event) => {
    setSearchCriteria(event.target.value);
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const searchVouchers = () => {
    if (!searchQuery) {
      return vouchers;
    }

    const filteredVouchers = vouchers.filter((voucher) => {
      const fieldValue = voucher[searchCriteria].toLowerCase();
      const searchValue = searchQuery.toLowerCase();
      return fieldValue.includes(searchValue);
    });

    return filteredVouchers;
  };

  const filteredVouchers = searchVouchers();

  const sendApproveRequest = async (num) => {
    setIsLoading(true);
    const res = await axios.put(
      `${baseUrl}/vouchers/${num}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    setIsLoading(false);
    if (res.status !== 200) {
      alert("Failed to approve voucher.");
    } else {
      fetchVouchers();
    }
  };

  const sendDeleteRequest = async (num, unapprove) => {
    setIsLoading(true);
    const res = await axios.delete(
      `${baseUrl}/vouchers/${num}${
        unapprove ? "?unapprove=true" : "?unapprove=false"
      }`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    setIsLoading(false);
    if (res.status !== 200) {
      alert("Failed to delete voucher.");
    } else {
      fetchVouchers();
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="spinner mx-auto m-5"></div>
      ) : (
        <Box sx={{ px: { xs: 0, md: 5, lg: 10 } }}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            mb={3.5}
          >
            <Grid item>
              <FormGroup row>
                <Select
                  size="small"
                  sx={{
                    borderRadius: "7px 0px 0px 7px",
                  }}
                  id="searchCriteria"
                  name="searchCriteria"
                  color="secondary"
                  value={searchCriteria}
                  onChange={handleSearchCriteriaChange}
                >
                  <MenuItem value="voucherNumber">Voucher Number</MenuItem>
                  <MenuItem value="description">Description</MenuItem>
                  <MenuItem value="category">Category</MenuItem>
                  <MenuItem value="location">Location</MenuItem>
                  <MenuItem value="account">Account</MenuItem>
                </Select>
                <TextField
                  size="small"
                  sx={{
                    borderRadius: 0,
                  }}
                  variant="outlined"
                  placeholder="Search"
                  color="secondary"
                  type="text"
                  id="searchQuery"
                  name="searchQuery"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                />
                <Button
                  size="small"
                  sx={{
                    borderRadius: "0px 7px 7px 0px",
                  }}
                  disableElevation
                  color="secondary"
                  variant="contained"
                >
                  <SearchIcon />
                </Button>
              </FormGroup>
            </Grid>

            <Grid display={"flex"} gap={2} item>
              <IconButton
                variant="contained"
                color="primary"
                onClick={exportToExcel}
              >
                <IosShareIcon />
              </IconButton>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate("/addVoucher");
                }}
              >
                Add Voucher
              </Button>
            </Grid>
          </Grid>

          <Grid container justifyContent={"space-between"}>
            {filteredVouchers?.map((voucher) => (
              <Grid key={voucher._id} item xs={12} md={5.5} lg={2.5} mb={2.5}>
                <VoucherCard
                  voucher={voucher}
                  sendApproveRequest={sendApproveRequest}
                  sendDeleteRequest={sendDeleteRequest}
                  user={user}
                  printVoucher={printVoucher}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
}
