import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Vouchers from "./pages/Vouchers";
import Invoices from "./pages/Invoices";
import AttendancePage from "./pages/AttendancePage";
import Login from "./pages/Login";
import { useAuthContext } from "./hooks/useAuthContext";
import EmployeeProfile from "./pages/EmployeeProfile";
import Addemployee from "./components/Addemployee";
import EditEmployee from "./components/EditEmployee";
import { useLogout } from "./hooks/useLogout";
import Payroll from "./pages/Payroll";
import EmployeeAttendance from "./pages/EmployeeAttendance";
import ResponsiveDrawer from "./components/Drawer";
import EmployeeList from "./components/Employeelist";
import AddVoucher from "./components/AddVoucher";
import AddCustomer from "./components/AddCustomer";
import CustomerList from "./components/Customerlist";
import AddInvoice from "./components/AddInvoice";
import UserPage from "./pages/UserPage";

function App() {
  const { user } = useAuthContext();
  const { logout } = useLogout();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const verifyUser = async () => {
    const response = await fetch(`${baseUrl}/user/verifytoken`, {
      method: "POST",
      headers: { Authorization: `Bearer ${user.token}` },
    });

    if (response.status === 401) {
      alert("Your session has expired, please login again.");
      logout();
    }
  };

  if (user?.token) verifyUser();

  return (
    <>
      <BrowserRouter>
        <ResponsiveDrawer>
          <Routes>
            <Route
              index
              element={user ? <Dashboard /> : <Navigate to="/login" />}
            />
            <Route
              path="/users"
              element={user ? <UserPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/employees"
              element={user ? <EmployeeList /> : <Navigate to="/login" />}
            />
            <Route
              path="/login"
              element={!user ? <Login /> : <Navigate to="/" />}
            />
            <Route
              path="/customers"
              element={user ? <CustomerList /> : <Navigate to="/login" />}
            />
            <Route
              path="/customers/add"
              element={user ? <AddCustomer /> : <Navigate to="/login" />}
            />
            <Route
              path="/vouchers"
              element={user ? <Vouchers /> : <Navigate to="/login" />}
            />
            <Route
              path="/addVoucher"
              element={user ? <AddVoucher /> : <Navigate to="/login" />}
            />
            <Route
              path="/invoices"
              element={user ? <Invoices /> : <Navigate to="/login" />}
            />
            <Route
              path="/invoices/add"
              element={user ? <AddInvoice /> : <Navigate to="/login" />}
            />
            <Route
              path="/attendance"
              element={user ? <AttendancePage /> : <Navigate to="/login" />}
            />
            <Route
              path="/employees/:id"
              element={user ? <EmployeeProfile /> : <Navigate to="/login" />}
            />
            <Route
              path="/attendance/:id"
              element={user ? <EmployeeAttendance /> : <Navigate to="/login" />}
            />
            <Route
              path="/employees/addEmployee"
              element={user ? <Addemployee /> : <Navigate to="/login" />}
            />
            <Route
              path="/employees/editEmployee"
              element={user ? <EditEmployee /> : <Navigate to="/login" />}
            />

            <Route
              path="/payroll"
              element={user ? <Payroll /> : <Navigate to="/login" />}
            />
          </Routes>
        </ResponsiveDrawer>
      </BrowserRouter>
    </>
  );
}

export default App;
